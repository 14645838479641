import React, { useState } from "react";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Contact, Popup } from "../../../components/popup";
import { DataSolutionsData } from "../../../data/data";
import OfferSlider from "../../../components/offer-slider";

const WebDevelopment = () => {
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowContact, setPopupShowContact] = useState(false);

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };

  const handlePopupModalContact = () => {
    setPopupShowContact(!popupShowContact);
  };
  return (
    <Layout>
      <div className="wrapper">
        <section className="serviceHeader dataSolutionsHeader">
          <div className="innerPageNavigation">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/services/data-solutions">Data solutions</Link>
              </li>
            </ul>
          </div>
          <div className="serviceContainer">
            <div className="heroContent">
              <h1>Data Solutions</h1>
              <p>
                Trusted by over 200 startups and other businesses. An agile shop
                for excellence in Data solutions
              </p>
              <button className="btn heroBtn" onClick={handlePopupModalContact}>
                Get an estimate
              </button>
            </div>
            <div className="servicePartner">
              <ul>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/enterprise-solutions">
                      Enterprise Solutions{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/mobility-solutions">
                      Mobility Solutions{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>

                <li>
                  <div className="partnerImg">
                    <Link to="/services/cloud-solutions">
                      Cloud Solutions
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="partnerImg">
                    <Link to="/services/qa-testing">
                      QA & Testing{" "}
                      <span className="circle">
                        <StaticImage placeholder="none"
                          alt="arrow"
                          src="../../../images/arrow.svg"
                        />
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="serviceDetailsSection dataSolutions">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="serviceDetailsImage">
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="cardgradient"
                        src="../../../images/servicedetails/service-details-3.svg"
                      />
                      <StaticImage placeholder="none"
                        alt="solutions"
                        className="mobile-cardgradient"
                        src="../../../images/servicedetails/service-details-3.svg"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="serviceContent">
                      <p>
                        <strong>We offer the best Data Solutions</strong> to
                        assist in the development of a data-driven approach and
                        the analysis of current operations for maximum business
                        benefit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="solutionSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="row">
                <div className="col-lg-6">
                  <h1>
                    <span className="circle"></span>
                    <span className="titleName">
                      Why choose Xminds for data solutions?
                    </span>
                  </h1>
                  <div className="solutionPoint">
                    <div
                      className="valuePoint"
                      data-aos-delay="100"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="5"
                    >
                      <h5>01</h5>
                      <p>
                        Identifying and resolving any potential threats in
                        advance
                      </p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="10"
                    >
                      <h5>02</h5>
                      <p>Experienced data management & High-quality services</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="15"
                    >
                      <h5>03</h5>
                      <p>Save time and money</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="1500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="20"
                    >
                      <h5>04</h5>
                      <p>Latest technology and superior security measures</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2000"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="25"
                    >
                      <h5>05</h5>
                      <p>Adaptable and flexible services</p>
                    </div>
                    <div
                      className="valuePoint"
                      data-aos-delay="2500"
                      data-aos="zoom-in"
                      data-aos-easing="linear"
                      data-aos-duration="30"
                    >
                      <h5>06</h5>
                      <p>Effective and efficient Algorithms</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="dataBox"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="serviceofferSection" id="dataSolutions">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>What we offer?
              </h1>
              <div className="testimonialService">
                <div className="serviceContainer">
                  <div className="authorRow">
                    <OfferSlider data={DataSolutionsData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contactSection serviceContactSeaction">
          <div className="container-fluid">
            <div className="wrapperService">
              <div className="contactRelative">
                <div className="contactTitle">
                  <div className="casestudySlider">
                    <h1>
                      <span className="circle"></span>
                      <span className="titleName">
                        We love to listen to your requirements
                      </span>
                    </h1>
                  </div>
                </div>

                <Contact
                  type="static"
                  handleChange={handlePopupModal}
                  key={[popupShow, popupShowContact]}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="ourofficeSection serviceOtherSection">
          <div className="container-fluid">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>Our other services
              </h1>
              <div className="serviceOtherContainer">
                <div className="row">
                  <div className="servicePartner">
                    <ul>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/enterprise-solutions">
                            Enterprise Solutions{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/mobility-solutions">
                            Mobility Solutions{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/cloud-solutions">
                            Cloud Solutions{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="partnerImg">
                          <Link to="/services/qa-testing">
                            QA & Testing{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="arrow"
                                src="../../../images/arrow.svg"
                              />
                            </span>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
      <Popup
        show={popupShowContact}
        handleClose={handlePopupModalContact}
        body="contactForm"
      />
    </Layout>
  );
};
export const Head = () => (
  <Seo
    title="Best Data Solutions, Machine Learning & AI | Xminds"
    description="We help businesses to build an efficient data-driven ecosystem by integrating the latest AI & Machine learning technologies to attain maximum efficiency."
    url="https://www.xminds.com/services/data-solutions/"
  />
);



export default WebDevelopment;
